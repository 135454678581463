<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>Webhook Key</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                A webhook key is used to sign messages sent to webhook receivers.
                            </p>

                            <template v-if="webhookKey">
                                <EditableText :value="webhookKey.label" @input="saveWebhookKeyLabel" dense>
                                    <template #view--after-mode-buttons>
                                        <v-btn icon @click="displayWebhookKeyContent">
                                            <font-awesome-icon :icon="['fas', 'eye']" class="green--text text--darken-2"></font-awesome-icon>
                                        </v-btn>
                                    </template>
                                </EditableText>
                                <v-alert v-model="displaySecretText" type="info" dismissible v-if="secretText">
                                    {{ secretText }}
                                </v-alert>
                                <p class="mb-2">Cipher algorithm: {{ webhookKey.cipher_alg }}</p>
                                <p class="mb-2">Key length: {{ webhookKey.key_length }}</p>
                                <!-- <EditableTextSelect :value="webhookKey.is_active" :items="isActiveChoices" @input="saveWebhookReceiverActive" dense></EditableTextSelect> -->
                                <!-- TODO: editable date with calenar view -->
                                <p class="mb-2">Not before: {{ formatDate(webhookKey.not_before) }}</p>
                                <p class="mb-2">Not after: {{ formatDate(webhookKey.not_after) }}</p>
                            </template>
                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span><font-awesome-icon :icon="['fas', 'shield-alt']" class="amber--text text--darken-2 mr-2"></font-awesome-icon> Manage Key</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteKeyImmediately">Delete Key</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EditableText from '@/components/EditableText.vue';
// import EditableTextSelect from '@/components/EditableTextSelect.vue';

export default {
    components: {
        EditableText,
        // EditableTextSelect,
    },
    data: () => ({
        organization: null,
        webhookKey: null,
        error: null,
        submitFormTimestamp: null,
        secretText: null,
        displaySecretText: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isViewReady() {
            return this.webhookKey !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditHostnameFormComplete() {
            return this.editableHostname;
        },
    },
    methods: {
        async loadWebhookKey() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebhookKey: true });
                const response = await this.$client.organization(this.$route.params.organizationId).webhookKey.get({ id: this.$route.query.id });
                console.log(`loadWebhookKey: response ${JSON.stringify(response)}`);
                if (response) {
                    this.webhookKey = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebhookKey: false });
            }
        },
        async editWebhookKey(edit = {}) {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                if (Object.keys(edit).length === 0) {
                    this.$bus.$emit('snackbar', { type: 'warn', headline: 'No changes to save' });
                    return;
                }
                this.$store.commit('loading', { editWebhookKey: true });
                // TODO: label, priority, url, is_active
                const response = await this.$client.organization(this.$route.params.organizationId).webhookKey.edit({ id: this.$route.query.id }, edit);
                console.log(`editWebhookKey: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadWebhookKey();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit webhook key' });
                }
            } catch (err) {
                console.error('failed to edit webhook key', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit webhook key' });
            } finally {
                this.$store.commit('loading', { editWebhookKey: false });
            }
        },
        async saveWebhookKeyLabel(value) {
            return this.editWebhookKey({ label: value });
        },
        async saveWebhookKeyCipherAlg(value) {
            return this.editWebhookKey({ url: value });
        },
        async saveWebhookKeyLength(value) {
            let priority;
            if (typeof value === 'string') {
                try {
                    priority = Number.parseInt(value, 10);
                } catch (err) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Priority must be a non-negative integer' }); // TODO: should be a message next to the input
                }
            } else if (typeof value === 'number') {
                priority = Math.round(value);
            }
            return this.editWebhookKey({ priority });
        },
        async saveWebhookReceiverActive(value) {
            return this.editWebhookKey({ is_active: value });
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        async deleteKeyImmediately() {
            try {
                this.error = false;
                this.$store.commit('loading', { deleteKeyImmediately: true });
                const response = await this.$client.organization(this.$route.params.organizationId).webhookKey.delete({ id: this.$route.query.id });
                console.log(`deleteKeyImmediately: response ${JSON.stringify(response)}`);
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Deleted' });
                    this.$router.replace({ name: 'organization-search-webhook-key', params: { organizationId: this.$route.params.organizationId } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete key' });
                }
            } catch (err) {
                console.error('failed to delete key', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete key' });
            } finally {
                this.$store.commit('loading', { deleteKeyImmediately: false });
            }
        },
        async displayWebhookKeyContent() {
            try {
                this.error = false;
                this.$store.commit('loading', { displayWebhookKeyContent: true });
                const response = await this.$client.organization(this.$route.params.organizationId).webhookKey.get({ id: this.$route.query.id, include: 'secret' });
                console.log(`displayWebhookKeyContent: response ${JSON.stringify(response)}`);
                if (response?.secret_base64) {
                    this.secretText = response.secret_base64;
                    this.displaySecretText = true;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to retrieve key' });
                }
            } catch (err) {
                console.error('failed to retrieve key', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to retrieve key' });
            } finally {
                this.$store.commit('loading', { displayWebhookKeyContent: false });
            }
        },
    },
    mounted() {
        this.loadWebhookKey();
    },
};
</script>
